exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-deutsch-js": () => import("./../../../src/pages/deutsch.js" /* webpackChunkName: "component---src-pages-deutsch-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-o-js": () => import("./../../../src/pages/o.js" /* webpackChunkName: "component---src-pages-o-js" */),
  "component---src-pages-publikacje-js": () => import("./../../../src/pages/publikacje.js" /* webpackChunkName: "component---src-pages-publikacje-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-wroclaw-js": () => import("./../../../src/pages/wroclaw.js" /* webpackChunkName: "component---src-pages-wroclaw-js" */),
  "component---src-pages-wycieczki-js": () => import("./../../../src/pages/wycieczki.js" /* webpackChunkName: "component---src-pages-wycieczki-js" */)
}

